<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="orderPage" :fields="fields" :search-model="searchModel"
                show-serial-no :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="state">
          <el-select v-model="searchModel.state" clearable placeholder="合同状态">
            <el-option v-for="(label, value) in stateMap"
                       :key="value"
                       :label="label"
                       :value="value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="type">
          <el-select v-model="searchModel.type" clearable placeholder="合同类型">
            <el-option v-for="iter in CONTRACT_TYPE"
                       :key="iter.label"
                       :label="iter.label"
                       :value="iter.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="" prop="webFarmerSearch" style="width: 90px">
          <el-input v-model="searchModel.webFarmerSearch" placeholder="电话号码" style="width: 90px;"></el-input>
        </el-form-item>
        <el-form-item  label="" prop="webFarmerProvinceSearch" style="width: 90px">
          <el-input v-model="searchModel.webFarmerProvinceSearch" placeholder="所在省" style="width: 90px;"></el-input>
        </el-form-item>
        <el-form-item label="" prop="webFarmerCitySearch" style="width: 90px">
          <el-input v-model="searchModel.webFarmerCitySearch" placeholder="市区"  style="width: 90px;"></el-input>
        </el-form-item>
        <el-form-item  label="" prop="webFarmerAreaSearch" style="width: 90px">
          <el-input v-model="searchModel.webFarmerAreaSearch" placeholder="县/区" style="width: 90px"></el-input>
        </el-form-item>
        <el-form-item  label="" prop="webFarmerAddressSearch" style="width: 120px">
          <el-input v-model="searchModel.webFarmerAddressSearch" clearable placeholder="详细地址" style="width:120px"></el-input>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <el-button v-permission="'ht:add'" @click="handleAdd" size="mini" type="primary">发起合同</el-button>
      </template>
    </page-table>
    <el-dialog title="微信扫码签署合作协议"
               destroy-on-close
               :visible.sync="signUrlShow"
               :before-close="beforeClose"
               width="30%">
      <div style="padding: 10px;display: flex;justify-content: center;">
        <el-image style="width: 300px;height: 300px;"
                  :src="signUrl"></el-image>
      </div>
      <div style="padding-top: 20px;text-align:center;">
        <div>企业已经静默完成签署</div>
        <div>请用户扫描二维码签约</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageTable from "@/components/PageTable";
import {orderTransfer, salesmanPage} from "@/api/common";
import helper, {getContractStatus, statusMapper} from "@/utils/helper";
import {CONTRACT_MANAGEMENT_STATUS, CONTRACT_TYPE, PIC_URL} from "@/utils/config";
import {contractPage, initiateRescission, signatureFlowAgain, signatureRevoke} from "@/api/apis";
import {exportPicture} from "@/utils/common";
export default {
  name: "list",
  components: {PageTable, PageHeader},
  data() {
    return {
      searchModel: {
        // 状态
        state: '',
        // 省
        webFarmerProvinceSearch: '',
        // 市
        webFarmerCitySearch: '',
        // 区域
        webFarmerAreaSearch: '',
        // 地址
        webFarmerAddressSearch: '',
        // 电话号码
        webFarmerSearch: '',
        // 合同类型
        type: '',
      },
      orderPage: contractPage,
      fields: [
        {
          label: '姓名',
          width: 110,
          render({farmer}, h) {
            return h('div', farmer ? farmer.name : '')
          }
        },
        {
          label: '电话号码',
          render({farmer}, h) {
            return h('div', farmer ? farmer.phone : '')
          }
        },
        {
          label: '所在地区',
          render: ({ farmer }, h) => {
            const { province, city, area } = farmer || {}
            return h('div', [province, city, area].join(''))
          }
        },
        {
          label: '详细地址',
          render: ({ farmer }, h) => {
            const { address } = farmer || {}
            return h('div', address)
          }
        },
        {
          label: '合同状态',
          width:100,
          render: ({ state }, h) => {
            return h('div', getContractStatus(state))
          }
        },
        {
          label: '签约方式',
          width:100,
          render: ({ signType }, h) => {
            return h('div', signType == 1 ? '线下签约' : '线上签约')
          }
        },
        {
          label: '所属公司',
          render({ createUser: user }, h) {
            const name = user?.distributor?.name || ''
            return h('div', name)
          }
        },
        // 合同类型
        {
          label: '合同类型',
          render({ type }, h) {
            const item = CONTRACT_TYPE.find(iter => String(iter.value) === type)
            return h('div', item && item.label)
          }
        },
        // 签署时间
        { label: '签署时间', key: 'singTime'}
      ],
      actions: [
        {
          action: 'info',
          label: '查看',
          type: 'primary',
          permission: 'ht:see',
          showAction: info => {
            // 线上签约 and 非撤回状态
            return info.signType != 1 && info.state != 'CANCEL'
          }
        },
        {
          action: 'download',
          label: '下载合同',
          type: 'primary',
          showAction: info => {
            // 线上签约 and 非撤回状态 and 有合同文件
            return info.signType != 1 && info.state != 'CANCEL' && !!info.localUrl
          }
        },
        // 撤回
        {
          action: 'withdraw',
          label: '撤回',
          type: 'primary',
          showAction: item => {
            return ['PART'].includes(item.state)
          }
        },
        // 重新发起合同
        {
          action: 'edit',
          label: '重新发起合同',
          type: 'primary',
          permission: 'ht:add',
          showAction: item => {
            return ['REJECT', 'DEADLINE'].includes(item.state) && item.canFlowAgain
          }
        },
        {
          action: 'revoke',
          label: '撤销签约',
          type: 'primary',
          showAction(item) {
            return item.state === 'ALL' && item.signType != 1
          }
        }
      ],
      stateMap: CONTRACT_MANAGEMENT_STATUS,
      CONTRACT_TYPE,
      //
      transfer_form_loading: false,
      transferForm: {
        id: '',
        userId: '',
      },
      transferRules: {
        userId: [
          {required: true, message: '请选择业务员', trigger: 'blur'},
        ],
      },
      transfer_dialog_visible: false,
      searech_user_id_loading: false,
      user_options: [],
      info_dialog_visible: false,
      curInfo: null,
      //
      signUrl: '',
      signUrlShow: false,
    }
  },
  created() {

  },
  mounted() {

  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    statusMapper,
    // action---------------------
    /**
     * 查看详情
     * @param item
     */
    infoAction({ localUrl, id, signType }) {
      if (localUrl) {
        const url = helper.getSourceUri(localUrl)
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = '_blank'
        document.body.appendChild(a);
        a.click();
        return
      }
      //
      if (signType == 1) {
        this.$router.push(`/admin/contract/info/${id}`)
      } else {
        this.$message.warning('合同未签署完成')
      }
    },
    revokeAction(item){
      this.$confirm('确认撤销签约吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({ fullscreen: true })
        initiateRescission(item.id).then((res) => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.signUrl = PIC_URL + '/code?code=' + res
            this.signUrlShow = true
            this.$refs.table && this.$refs.table.loadData()
          }, 500)
        }).finally(() => {
          loading.close()
        })
      })
    },
    /**
     * 下载合同
     */
    downloadAction({ localUrl }){
      if (localUrl) {
        const name = ((localUrl || '').split('/') || []).reverse()[0]
        exportPicture(helper.getSourceUri(localUrl), name)
      }
    },
    /**
     * 编辑
     */
    editAction(item){
      this.$confirm('确认重新发起合同吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({ fullscreen: true })
        signatureFlowAgain(item.id).then(res => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.signUrl = PIC_URL + '/code?code=' + res
            this.signUrlShow = true
          }, 500)
        }).finally(() => {
          loading.close()
        })
      })
    },
    beforeClose(done){
      this.$refs.table && this.$refs.table.loadData()
      done()
    },
    /**
     * 撤回
     * @param item
     */
    withdrawAction(item){
      this.$confirm('确认撤回吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({ fullscreen: true })
        signatureRevoke(item.id).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$refs.table && this.$refs.table.loadData()
          }, 500)
        }).finally(() => {
          loading.close()
        })
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    // --------------------------
    /**
     * 发起合同
     */
    handleAdd(){
      this.$router.push('/admin/contract/select')
    },
    userIdRemoteMethod(query) {
      if (query == '') return
      let that = this
      that.searech_user_id_loading = true
      salesmanPage({developerName: query}).then(res => {
        that.user_options = res.records
        that.searech_user_id_loading = false
      }).catch(() => {
        that.user_options = []
        that.searech_user_id_loading = false
      })
    },
    transferSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.transfer_form_loading = true
        orderTransfer(that.transferForm.id, that.transferForm.userId).then(() => {
          that.transfer_form_loading = false
          that.$message.success('提交成功')
          that.transfer_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.transfer_form_loading = false
        })
      })
    },
  },
}
</script>

<style scoped>

</style>